import {useState} from 'react';
import '../css/sidebar.css'
import logo from "../assets/img/logo.png"
import {
    CCollapse,
    CContainer,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
    CNavLink
} from "@coreui/react";

export default function SiteHeader() {


    const [visible, setVisible] = useState(false)
    const vars = {
        '--bs-navbar-color': "#F8B600",
        '--bs-navbar-hover-color': "#f3cc79",
    }

    return (
        <section className="home_banner_area">
            <CNavbar style={vars} expand="lg" className="bg-body-tertiary bg-transparent">
                <CContainer fluid>
                    <CNavbarBrand href="/">
                        <img src={logo} alt="" width="250" height="150"/>
                    </CNavbarBrand>
                    <CNavbarToggler onClick={() => setVisible(!visible)}/>
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav className="ml-auto font-weight-bold">
                            <CNavItem>
                                <CNavLink href="/">Domů</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/aboutUs.html">O nás</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/projects.html">Kovovýroba</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/building">Stavební a zemní práce</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/Pneuservice">Pneuservis</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/categories">Prodej</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/rentalCategories">Půjčovna</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink href="/contact.html">Kontakt</CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar>
            <div className="banner_inner">
                <div className="container">
                    <div className="row">
                        <div className="banner_content">
                            <h2>Tam, kde konkurence končí, Bumis teprve začíná!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}