import 'bootstrap/dist/css/bootstrap.min.css';

import sluzbyImg from '../assets/img/kovovyroba.jpg'
import bazarImg from '../assets/img/bazar.jpg'
import prodejImg from '../assets/img/prodej.jpg'
import buildingImg from '../assets/img/BuildingWork.jpg'
import pneuserviceImg from '../assets/img/pneuservice.jpg'

export default function LandPage() {

    return (
        <div>
            <section className="projects_area">
                <div className="main_title">
                    <h2>VYBERTE SI Z NAŠÍ NABÍDKY</h2>
                </div>
            </section>

            <section className="feature_area">
                <div className="container">
                    <div className="row feature_inner">
                        <div className="col-lg-3 col-md-8">
                            <div className="feature_item">
                                <a href="/projects.html">
                                    <img className="image_style" src={sluzbyImg} alt=""/>
                                    <div className="hover">
                                        <h4>Kovovýroba</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-8">
                            <div className="feature_item">
                                <a href="./Building">
                                    <img className="image_style" src={buildingImg} alt=""/>
                                    <div className="hover">
                                        <h4>Stavební a zemní práce</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-8">
                            <div className="feature_item">
                                <a href="./Pneuservice">
                                    <img className="image_style" src={pneuserviceImg} alt=""/>
                                    <div className="hover">
                                        <h4>Pneuservice</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-8">
                            <div className="feature_item">
                                <a href="./Categories">
                                    <img className="image_style" src={prodejImg} alt=""/>
                                    <div>
                                        <h4>Prodej</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-8">
                            <div className="feature_item">
                                <a href="./RentalCategories">
                                    <img className="image_style" src={bazarImg} alt=""/>
                                    <div>
                                        <h4>Půjčovna strojů</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-8">

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}