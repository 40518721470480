import React from 'react'
import zouvackaImg from "../assets/img/zouvacka.jpg"
import vyvazovackaImg from "../assets/img/vyvazovacka.jpg"
import myckaKolImg from "../assets/img/pneuMycka.jpg"
import ContactForm from "../components/ContactForm";

function Pneuservice() {
    return (
        <section className="projects_area">
            <div className="main_title" style={{textAlign: "left"}}>
                <h3>Profesionální pneuservis pro všechny typy pneumatik</h3>
                <p>Náš pneuservis nabízí komplexní služby pro osobní vozy, motocykly, zemědělské stroje, dodávky a lehká
                    nákladní vozidla. Zaměřujeme se na špičkovou péči o pneumatiky a zajišťujeme bezpečnost a dlouhou
                    životnost vašich kol.</p>
                <h3 className="mt-5">Naše hlavní služby:</h3>
                <ul className="list-unstyled">
                    <li className="mt-2"><b>Prodej pneumatik a příslušenství:</b> Široký výběr kvalitních pneumatik pro
                        osobní, motocyklová, užitková a zemědělská vozidla včetně speciálních modelů pro traktory značek
                        Kubota, Iseki, Zetor a další. Poskytujeme odborné poradenství při výběru vhodných pneumatik dle
                        typu vozu a podmínek.
                    </li>
                    <li className="mt-2"><b>Montáž a vyvažování pneumatik:</b> PS našimi profesionálními stroji Sicam
                        520 Special a Sicam SBM 150 zajistíme přesné a bezpečné vyvážení pro nízkoprofilové, Run-flat,
                        motocyklové i zemědělské pneumatiky.
                    </li>
                    <li className="mt-2"><b>Čištění pneumatik a kol:</b> Důkladné čištění pomocí myčky Wulkan 360P pro
                        všechny typy kol až do průměru 31". Profesionální péče o vzhled a životnost vašich kol.
                    </li>
                    <li className="mt-2"><b>Opravy, lepení a prořezávání pneumatik:</b> Provádíme kvalitní opravy,
                        lepení i prořezávání pneumatik, čímž prodlužujeme jejich životnost a zajišťujeme bezpečný
                        provoz.
                    </li>
                    <li className="mt-2"><b>Pískování disků:</b> Zbavíme disky koroze a starého laku a připravíme je pro
                        další úpravy nebo nový nátěr. Výsledek? Obnovený vzhled a dlouhodobá ochrana.
                    </li>
                </ul>
                <div className="text-center">
                    <ContactForm isWithDays={false}/>
                </div>

                <h3 className="mt-5">Stroje a jejich parametry:</h3>
                <div className="row mb-2">
                    <div className="col-md-6">
                        <div
                            className="row no-gutters  rounded overflow-hidden flex-md-row mb-4 shadow-sm position-relative">
                            <div className="col p-4 d-flex flex-column position-static">
                                <h3 className="mb-o">Zouvačka Sicam 520 Special s ramenem Technoloroller</h3>
                                <p className="card-text mb-auto">
                                    Efektivní a bezpečná montáž všech typů pneumatik s rozsahem úchopu od 6“ do 25“.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-auto d-none d-lg-block">
                            <img alt="zouvacka"
                                 style={{maxWidth: '70%', height: "auto", objectFit: "scale-down"}}
                                 src={zouvackaImg}/>

                        </div>
                    </div>
                </div>


                <div className="row mb-2">
                    <div className="col-md-6">
                        <div
                            className="row no-gutters  rounded overflow-hidden flex-md-row mb-4 shadow-sm position-relative">
                            <div className="col p-4 d-flex flex-column position-static">
                                <h3 className="mb-o">Vyvažovačka Sicam SBM 150r</h3>
                                <p className="card-text mb-auto">
                                    Maximální přesnost vyvážení až do průměru kol 26".
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-auto d-none d-lg-block">
                            <img alt="zouvacka"
                                 style={{maxWidth: '70%', height: "auto", objectFit: "scale-down"}}
                                 src={vyvazovackaImg}/>

                        </div>
                    </div>
                </div>


                <div className="row mb-2">
                    <div className="col-md-6">
                        <div
                            className="row no-gutters  rounded overflow-hidden flex-md-row mb-4 shadow-sm position-relative">
                            <div className="col p-4 d-flex flex-column position-static">
                                <h3 className="mb-o">Myčka Wulkan 360P</h3>
                                <p className="card-text mb-auto">
                                    Důkladné čištění pneumatik a ráfků s možností práce s koly do průměru 31".
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-auto d-none d-lg-block">
                            <img alt="zouvacka"
                                 style={{maxWidth: '70%', height: "auto", objectFit: "scale-down"}}
                                 src={myckaKolImg}/>

                        </div>
                    </div>
                </div>

                <h3>Proč právě náš pneuservis?</h3>
                <ul className="list-unstyled">
                    <li className="mt-2"><b>Špičkové vybavení:</b> Moderní vybavení pro jakékoliv pneumatiky a kola.
                    </li>
                    <li className="mt-2"><b>Zkušený tým: </b> Naši technici mají bohaté zkušenosti s péčí o různé typy
                        pneumatik.
                    </li>
                    <li className="mt-2"><b>Komplexní péče o kola:</b>Od prodeje a montáže přes opravy až po estetickou
                        úpravu kol, vše na jednom místě.
                    </li>
                </ul>
                <p>Obraťte se na nás, ať už potřebujete pneuservisní služby pro osobní, nákladní, motocyklové nebo
                    zemědělské pneumatiky. Naši odborníci vám rádi pomohou s výběrem a zajistí vám bezpečný a pohodlný
                    provoz.</p>
            </div>
        </section>
    )
}

export default Pneuservice
